@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/inter-v11-latin-regular.woff2) format('woff2'),
    url(./fonts/Inter/inter-v11-latin-regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter-bold';
  src: url(./fonts/Inter/inter-v11-latin-700.woff2) format('woff2'),
    url(./fonts/Inter/inter-v11-latin-700.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-size: 18px;
  font-family: 'Inter', 'Helvetica', sans-serif;
  background-image: url(./images/bg-pattern-23.png);
  background-repeat: repeat;
  background-size: 858px;
}

.ios-standalone .ios-extra-padding {
  padding-bottom: 2rem;
}

/* remove this blue/green square when clicked on mobile */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: white;
  text-decoration: none;
}

#root {
  max-width: 1000px;
  min-height: 100%;
  position: relative;
  margin: auto;
  outline: 2px solid white;
}

h1 {
  font-family: 'Inter-bold';
  font-size: 32px;
}

h3 {
  font-family: 'Inter-bold';
  font-size: 24px;
  text-transform: uppercase;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

.mapboxgl-user-location-dot:before,
.maplibregl-user-location-dot:before {
  left: 0px;
}

.mapboxgl-popup-content {
  background: black !important;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 45px;
  left: 30px;
  font-size: 10px;
  padding: 10px 5px !important;
}

.maplibregl-popup-tip.mapboxgl-popup-tip {
  display: none;
}

.maplibregl-user-location-dot {
  background-color: #af3db4 !important;
}

.maplibregl-user-location-dot::before {
  background-color: #af3db4 !important;
}

.maplibregl-user-location-accuracy-circle {
  background-color: #ea1df233 !important;
}

.maplibregl-ctrl {
  display: none;
}
